<template>
  <div class="box-container">
    <a-modal v-model="modalVisible" style="margin-top: 10px;" width="50%">
      <template slot="footer">
        <a-button type="primary" @click="updateAllAgain">失败设备全部重发</a-button>
        <a-button @click="handleCancel">关闭</a-button>
      </template>
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">升级结果详情</h3>
        </div>
        <div class="box-container-inner">
          <div class="simple-query">
            <a-form-model ref="queryParams" :model="queryParams" layout="inline" class="query-form">
              <a-form-model-item label="设备编码" prop="devicenum">
                <a-input v-model="queryParams.devicenum" placeholder="请输入" style="width: 186px"></a-input>
              </a-form-model-item>
              <a-form-model-item label="升级指令下发结果" prop="orderresult">
                <a-select v-model="queryParams.orderresult" style="width:140px">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option value="成功">成功</a-select-option>
                  <a-select-option value="失败">失败</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="升级结果" prop="result">
                <a-select v-model="queryParams.result" style="width:140px">
                  <a-select-option value="">请选择</a-select-option>
                  <a-select-option value="成功">成功</a-select-option>
                  <a-select-option value="失败">失败</a-select-option>
                </a-select>
              </a-form-model-item>
              <div class="query-btns" style="margin-left: 40%">
                <a-button @click="resetQueryParams">重置</a-button>
                <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
                <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
              </div>
            </a-form-model>
          </div>
          <div v-show="showAdvanced" class="advanced-query">
            <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
<!--              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepid">-->
<!--                <a-cascader :field-names="{ label: 'userdepname', value: 'userdepname', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--              </a-form-model-item>-->
<!--              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepid">-->
<!--                <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择维护单位" :change-on-select="true" :allow-clear="false"></a-cascader>-->
<!--              </a-form-model-item>-->
              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='1'" label="使用单位" prop="userdepname">
                <a-input v-model="queryParams.userdepname" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item v-if="userInfo.usertype=='0'||userInfo.usertype=='3'" label="维护单位" prop="maintenancedepname">
                <a-input v-model="queryParams.maintenancedepname" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="所在区域" prop="areacode">
                <a-cascader :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }" :options="areaOptions" v-model="queryAreaSelected" :load-data="areaOnLoadChildren" placeholder="请选择区域" :change-on-select="true"></a-cascader>
              </a-form-model-item>
              <a-form-model-item label="电梯注册码" prop="liftnum">
                <a-input v-model="queryParams.liftnum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电梯救援码" prop="liftrescue">
                <a-input v-model="queryParams.liftrescue" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电梯登记编号" prop="regesternum">
                <a-input v-model="queryParams.regesternum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电梯追溯码" prop="traceabilitynum">
                <a-input v-model="queryParams.traceabilitynum" placeholder="请输入"></a-input>
              </a-form-model-item>
              <a-form-model-item label="电梯地址" prop="adress">
                <a-input v-model="queryParams.adress" placeholder="请输入"></a-input>
              </a-form-model-item>
            </a-form-model>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" type="index" row-key="upgradeid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="result" slot-scope="value">
            <a-tag :color="value==='成功'?'green':'red'">{{value}}</a-tag>
          </span>
          <span slot="update" slot-scope="value,record">
              <a-button v-show="show" v-if="value=='成功'?show=false:show=true" type="link" size="small" @click="updateAgain(value,record)">重新下发</a-button>
          </span>
          <span slot="isonline" slot-scope="value">
            <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
          </span>
          <span slot="orderresult" slot-scope="value">
            <a-tag :color="value==='成功'?'green':'red'">{{value}}</a-tag>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}/${counts}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </a-modal>
  </div>
</template>

<script>
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {
  calculateVersion,
  findUpgradeLogListByCondition,
  getDevicemoduleinfoListByCondition,
  updateVersion,
  upgradeVersion
} from "A/ai";
import { mapState } from 'vuex'
export default {
  mixins: [areaselect, deptselect, pagination],
  name: "UpdateDetail",
  props: {
    visible: {
      default: false
    },
    UpdateDetail:{
      default: null
    }
  },
  data(){
    return{
      areaOptions: [],
      areaCascaderSelected: [],
      queryAreaSelected: [],
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      showAdvanced: false,
      queryParams: {
        devicenum:'',
        result:'',
        order:'',
        adress:'',
        userdepname:'',
        userdepid:'',
        areaname:'',
        areacode:'',
        maintenancedepid:'',
        maintenancedepname:'',
        liftnum:'',
        liftrescue:'',
        regesternum:'',
        traceabilitynum:'',
        orderresult:'',
      },
      modalVisible: false,
      tableColumns: [
        {
          title: '设备名',
          dataIndex: 'upgradeobj',
          key: 'upgradeobj',
          ellipsis: true,
        },
        {
          title: '电梯地址',
          dataIndex: 'adress',
          key: 'adress',
          ellipsis: true,
        },
        {
          title: '版本号',
          dataIndex: 'version',
          key: 'version',
          ellipsis: true,
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
        {
          title: '升级指令下发结果',
          dataIndex: 'orderresult',
          key: 'orderresult',
          ellipsis: true,
          scopedSlots: { customRender: 'orderresult' }
        },
        {
          title: '升级结果',
          dataIndex: 'result',
          key: 'result',
          ellipsis: true,
          scopedSlots: { customRender: 'result' }
        },
        {
          title: '重新下发',
          dataIndex: 'result',
          key: 'update',
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'update' }
        },
      ],
      tableData: [],
      tableLoading: false,
      upgradeid:[],
      devicenum: [],
      devicemoduletype:[],
      backversion:[],
      flag:false,
      show:false,
      i:0,
      counts:'',
    }
  },
  computed :{
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData(true);
        this.resetQueryParams();
        this.showAdvanced=false;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepname = val[val.length-1];
      }else {
        this.queryParams.userdepname = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods:{
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryParams.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    handleCancel(){
      this.modalVisible=false;
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.UpdateDetail.devicegroupid=='00'){
        let params={
          ...this.queryParams,
          // upgradeid:this.UpdateDetail.upgradeid,
          devicegroupid:this.UpdateDetail.devicegroupid,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        }
        findUpgradeLogListByCondition(params).then(res=>{
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        })
      }else{
        let params={
          ...this.queryParams,
          upgradeid:'',
          devicegroupid:this.UpdateDetail.devicegroupid,
          upgradedate:this.UpdateDetail.upgradedate,
          upgradetype:this.UpdateDetail.devicegroupid=='0'?'0':'1',
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        }
        if(params.devicenum==''&&params.adress==''&&params.order==''&&params.result==''&&params.userdepname==''&&params.maintenancedepname==''&&params.areacode==''&&params.liftnum==''&&params.liftrescue==''&&params.regesternum==''&&params.traceabilitynum==''&&params.orderresult==''){
          findUpgradeLogListByCondition(params).then(res=>{
            this.upgradeid.splice(0,10);
            this.tableLoading = false;
            if(res && res.returncode == '0') {
              this.tableData = res.item;
              this.counts = res.count;
            }
          })
        }
        findUpgradeLogListByCondition(params).then(res=>{
          this.upgradeid.splice(0,10);
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        })
      }
    },
    getCalculateVersion(){
      let params = {
        upgradedate: this.tableData[0].upgradedate,
        modeltype: this.tableData[0].modeltype,
        upgradetype: this.tableData[0].upgradetype,
      }
      calculateVersion(params)
    },
    updateAllAgain() {
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].result == '失败'||this.tableData[i].result=="") {
          break;
        } else if(i==this.tableData.length-1){
          this.$message.info("设备已全部升级成功，请勿重复升级")
        }
      }
      for (var i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].result == '失败'||this.tableData[i].result=="") {
          let params = {
            devicenum: this.tableData[i].devicenum,
            data: [{
              modeltype: this.tableData[i].modeltype,
              modelnum: this.modelnum,
              version: this.tableData[i].version,
              file: this.tableData[i].file,
              md5: this.tableData[i].md5,
            }]
          }
          updateVersion(params)
          let data={
            upgradedate: this.tableData[i].upgradedate,
            modeltype: this.tableData[i].modeltype,
            upgradetype: this.tableData[i].upgradetype,
            precompletetime: '1800000',
          }
          calculateVersion(data)
          // this.$message.success('操作成功');
          // this.$emit('get-operation-result', 'success', '操作成功');
          // this.sertSuccess();
        }
      }
    },

    updateAgain(value,record){
      console.log(record)
      let params = {
        devicenum: record.devicenum,
        data: [{
          modeltype: record.modeltype,
          modelnum: this.modelnum,
          version: record.version,
          file: record.file,
          md5: record.md5,
        }]
      }
      updateVersion(params)
      this.$message.success('操作成功');
      let data={
        upgradedate: record.upgradedate,
        modeltype: record.modeltype,
        upgradetype: record.upgradetype,
        precompletetime: '1800000',
      }
      calculateVersion(data)
      // this.$emit('get-operation-result', 'success', '操作成功');
      // this.sertSuccess();
    },
  },
}
</script>

<style scoped>

</style>